import { Button } from "react-bootstrap";
import { PlaidLink, usePlaidLink } from "react-plaid-link";
import { useEffect, useState } from "react";
import { 
  useCreatePaymentLinkMutation,
   useGetBankDetailQuery, 
  useGetPayOutVerifyQuery, 
  usePostBankDetailsMutation,
   usePostDisconnectBankAccountMutation, 
   usePostVerifyBankAccountMutation
} from "../../../api/network/paymenttApiServices";
import { BANK_ACCOUNT, BASE_URL } from "../../../api/network/ServerUrl";
import { fetchUserData } from "../../../utils/storage";
import { toast } from "react-toastify";
import { set } from "lodash";
import Loader from "../../../components/loader/Loader";
import { Grid } from "@mui/material";

const PaymentMethods = () => {
  interface BankDetail {
    bank_name: string;
    account_number: string;
    routing_number: string;
    last4: string;
    stripe_bank_account_id: string;
  }
  
  const [bankDetails, setBankDetails] = useState<BankDetail | undefined>(undefined); // Initialize as undefined
  const [linkToken, setLinkToken] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [payout_verified, setPayoutVerified] = useState(false);
  const [deleteDisabled, setDeleteDisabled] = useState(true);
  const [postCreatePayment] = useCreatePaymentLinkMutation();
  const [postBankDetails] = usePostBankDetailsMutation();
  const userData = fetchUserData();
  const [toastDisplayed, setToastDisplayed] = useState(false);
  const [postDisconnectAccount, { isLoading, isSuccess, isError, error }] = usePostDisconnectBankAccountMutation();
  const getPayOutVerify = useGetPayOutVerifyQuery();  
  const [postVerifyBank] = usePostVerifyBankAccountMutation();
  const fetchData = async () => {
    try {
      const fetchResponse = await fetch(`${BASE_URL}bank_payment/api/v1/bank-account/`, {
        method: 'GET',
        headers: {
          'Authorization': `Token ${userData.token}` // Include your access token here
        }
      });
      const data = await fetchResponse.json();
      console.log("#$Data....", data);
      setBankDetails(data); // Set the bank details

      if (!data?.is_verified) {
        const response: any = await postCreatePayment(); // Pass an empty object as an argument
        if ('data' in response) {
          const { data } = response; // Access the 'data' property of the response object
          console.log(data);
          data && setLinkToken(data.link_token);
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
    getPayOutVerify.refetch().then((res) => {
      console.log("PayOutVerify", res?.data?.is_payout_verified);
      setPayoutVerified(res?.data?.is_payout_verified);
    }
    );
  console.log("Bank Details", bankDetails?.bank_name);
  }, []);

  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess: (public_token, metadata) => {
      if (public_token && metadata) {
        try {
          postBankDetails({ public_token: public_token, account_id: metadata.accounts[0].id }).unwrap().then((res) => {
            console.log("Bank Details", res);
            if (res.is_verified) {
              setDisabled(true);
              fetchData();
              toast.success("Bank Details Linked Successfully!");
            }
          });
        } catch (e) {
          console.log("Error", e);
        }
      }
    }
  });

  const handleDisconnectBank = async (bankAccountId:any) => {
    console.log("Bank Account Id", bankAccountId);
    if(!bankAccountId || bankAccountId === "" || bankAccountId === undefined){
      toast.error("There is no bank account to delete!");
      return;
    }
try{
  postDisconnectAccount(bankAccountId).unwrap().then((res) => {
    console.log("Bank Details", res);
      setDisabled(false);
      fetchData();
      setBankDetails(undefined);
      toast.success("Bank Account Deleted Successfully!");
      // setDisabled(true);

  }).catch((e) => {
  toast.error("Error In Deleting Bank Account!")})
}
catch(e){
  console.log("Error", e);
  toast.error("Error In Deleting Bank Account!");
}
  };

  const handleVerifyBank = async () => {
    try {
      const response = await postVerifyBank({}).unwrap();
      console.log("Bank Details", response);
  
      if (response.url) {
        window.location.href = response.url;
      }
    } catch (e) {
      console.log("Error", e);
    }
  };
  
  

  return (
    <>
    <Grid container display={'flex'} gap={2} >
      <Button onClick={() => open()} disabled={!ready || disabled} >Link your bank account</Button>
      { bankDetails!==undefined && bankDetails?.bank_name!==undefined &&
        <Button onClick={handleVerifyBank} disabled={payout_verified}>Verify your bank account</Button>}
      </Grid>
      {
        bankDetails!==undefined && bankDetails?.bank_name!==undefined &&
        <table className="m-2">
        <th className="p-4">Bank Details</th>
        <tr>
        <td>Bank Name: {bankDetails?.bank_name}</td>
        <td>Account-No: {`XXXXXXXX${bankDetails?.last4}`}</td>
        <td>
          <Button onClick={()=>{handleDisconnectBank(bankDetails?.stripe_bank_account_id)}}
            // disabled={!disabled}
            >Delete Bank Account</Button>
        </td>
        </tr>
      </table>}
    </>
  );
};

export default PaymentMethods;
