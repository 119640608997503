import { FormikProvider, useFormik, useFormikContext } from 'formik'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { AddJobPayload, JobProposedRatePayload } from '../../../api/types/Jobs'
import { postJobSchema } from '../../../utils/Validations'
import CardWithHeader from '../../../components/cardWithHeader/CardWithHeader'
import FormControl from '../../../components/formControl/FormControl'
import PrimaryButton from '../../../components/primaryButton/PrimaryButton'
import {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  ReactPortal,
  useEffect,
  useRef,
  useState
} from 'react'
import FormSelect from '../../../components/formSelect/FormSelect'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useProposedRateMutation,
  usePostJobMutation,
  useJobDetailQuery,
  usePutJobMutation
} from '../../../api/network/jobsApiService'
// import BottomTooltip from '../../../components/BottomTooltip'
import { ToastContentProps, toast } from 'react-toastify'
import Loader from '../../../components/loader/Loader'
import _ from 'lodash'

import './add-jobs.scss'
import { LocationObject, RouteDistancePayload } from '../../../api/types/Radar'
import { useRouteDistanceQuery } from '../../../api/network/radarApiService'
import { ftToMiles } from '../../../utils/NumberUtils'
import { APPCONFIG } from '../../../app-config'
import BottomTooltip from '../../../components/BottomTooltip/BottomTooltip'
import InfoNewIcon from '../../../assets/images/svg/INFO_NEW'
import { usePostMakePaymentMutation } from '../../../api/network/paymenttApiServices'
import { ROUTE_NAMES } from '../../../utils/route/routerNames'
import { BASE_URL, PAYMENT_HISTORY } from '../../../api/network/ServerUrl'
import { fetchUserData } from '../../../utils/storage'
import { Label } from 'recharts';

const PageAddJobs = () => {
  const refCustomerPriority: React.RefObject<HTMLInputElement> = useRef(null)
  interface BankDetail {
    bank_name: string;
    account_number: string;
    routing_number: string;
    last4: string;
    stripe_bank_account_id: string;
  }
  
  const [bankDetails, setBankDetails] = useState<BankDetail | undefined>(undefined);
 const nav=useNavigate()
  const [routeDistanceParams, setRouteDistanceParams] =
    useState<RouteDistancePayload>({
      origin: '',
      destination: '',
      modes: 'car'
    })
  const { data: dataRouteDistance, isLoading: isLoadingRouteDistance } =
    useRouteDistanceQuery(routeDistanceParams, {
      skip: !!!routeDistanceParams.origin || !routeDistanceParams.origin
    })
  const { jobId }: { jobId?: string } = useParams()
  const { data: jobDetail } = useJobDetailQuery(`${jobId}`, { skip: !jobId })
  const [putJobApiTrigger, { isLoading: isLoadingPutJobApi }] =
    usePutJobMutation()

  // const { data: jobDetailData } = useJobDetailQuery(jobId || '0')

  const [postJobApiTrigger, { isLoading }] = usePostJobMutation()
  const [postAmountApi] =usePostMakePaymentMutation()
  const [
    proposedRateTrigger,
    {
      data: dataProposedRate,
      isLoading: isLoadingProposedRate,
      reset: resetProposedRate
    }
  ] = useProposedRateMutation()

  const [visibility, setVisibility] = useState(1)
  const [loadtype, setLoadType] = useState([
    {
      id: '1',
      name: 'Dry'
    },
    {
      id: '2',
      name: 'Refer'
    }
  ])
  const [getSize, setSize] = useState([
    {
      id: '20',
      name: 20
    },
    {
      id: '40',
      name: 40
    },
    {
      id: '45',
      name: 45
    },
    {
      id: '53',
      name: 53
    }
  ])
  const [getHazMatType, setHazMatType] = useState([
    {
      id: 1,
      name: 'Explosives'
    },
    {
      id: 2,
      name: 'Gases'
    },
    {
      id: 3,
      name: 'Flammable Liquid & Combustible Liquid'
    },
    {
      id: 4,
      name: 'Flammable solid, Spontaneously Combustible & Dangerous When Wet'
    },
    {
      id: 5,
      name: 'Oxidizer & Organic Peroxide'
    },
    {
      id: 6,
      name: 'Poison (Toxic) & Poison Inhalation Hazard'
    },
    {
      id: 7,
      name: 'Radioactive'
    },
    {
      id: 8,
      name: 'Corrosive'
    },
    {
      id: 9,
      name: 'Miscellaneous'
    },
    {
      id: 10,
      name: 'Dangerous: Indicates a mixed load of hazardous materials'
    }
  ])
  const [paymentMethod, setPaymentMethod] = useState([
    {
      id: '1',
      name: 'Bank Account'
    }
  ])
  const [showStartError, setStartError] = useState(
    'Starting Location is required'
  )
  const [showEndError, setEndError] = useState('End Location is required')
  const [paymentStatus,setPaymentStatus]=useState('')
  const [payment_id,setPaymentId]=useState('')
  const [historyId,setHistoryId]=useState('')

  const formatTime = (dateString: string) => {
    const dateObject = new Date(dateString)
    const hours = dateObject?.getHours()
    const minutes = dateObject?.getMinutes()
    const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${
      minutes < 10 ? '0' : ''
    }${minutes}`
    return formattedTime
  }

  const convertToISOString = (
    appointmentDate: string,
    appointmentTime: string
  ) => {
    const dateTimeString = `${appointmentDate}T${appointmentTime}:00`

    // Create a Date object from the combined string
    const dateTime = new Date(dateTimeString)

    // Get the ISO string representation
    const isoString = dateTime.toISOString()

    return isoString
  }

  const initialValues: AddJobPayload = {
    id: jobDetail?.job_details?.id,
    job_description: jobDetail?.job_details?.job_description || '',
    location: jobDetail?.job_details?.location || 'California',
    contact_name: jobDetail?.job_details?.contact_name || '',
    phone_number: jobDetail?.job_details?.phone_number || '',
    visibility: 1,
    proposed_rate:
      jobDetail?.job_details?.financial_details?.proposed_rate || '',
    customer_priority:
      jobDetail?.job_details?.financial_details?.customer_priority || '0',
    total_rate: jobDetail?.job_details?.financial_details?.total_rate || '',
    independent_contractor_rate:
      jobDetail?.job_details?.financial_details?.independent_contractor_rate ||
      '',
    load_type: jobDetail?.job_details?.pickdrop_details?.load_type || '',
    appointment_date_time:
      jobDetail?.job_details?.pickdrop_details?.appointment_date_time || '',
    appointment_date:
      jobDetail?.job_details?.pickdrop_details?.appointment_date_time?.split(
        'T'
      )[0] || '',
    appointment_time: formatTime(
      jobDetail?.job_details?.pickdrop_details?.appointment_date_time || ''
    ),
    appointment_number:
      jobDetail?.job_details?.pickdrop_details?.appointment_number || '',
    weight: jobDetail?.job_details?.pickdrop_details?.weight || '',
    is_hazmat: jobDetail?.job_details?.pickdrop_details?.is_hazmat || false,
    size: jobDetail?.job_details?.pickdrop_details?.size || '',
    type_of_hazmat:
      jobDetail?.job_details?.pickdrop_details?.type_of_hazmat || '',
    starting_location:
      jobDetail?.job_details?.pickdrop_details?.starting_location || '',
    ending_location:
      jobDetail?.job_details?.pickdrop_details?.ending_location || '',
    pick_up_time:
      jobDetail?.job_details?.pickdrop_details?.pick_up_time || '09:30:00',
    scheduled_pick_up_date:
      jobDetail?.job_details?.pickdrop_details?.scheduled_pick_up_date || '',
    scheduled_pick_up_time:
      jobDetail?.job_details?.pickdrop_details?.scheduled_pick_up_time || '',
    container_number:
      jobDetail?.job_details?.pickdrop_details?.container_number || '',
    time_limit: jobDetail?.job_details?.pickdrop_details?.time_limit || '',
    starting_location_latitude:
      jobDetail?.job_details?.pickdrop_details?.starting_location_latitude ||
      '',
    starting_location_longitude:
      jobDetail?.job_details?.pickdrop_details?.starting_location_longitude ||
      '',
    ending_location_latitude:
      jobDetail?.job_details?.pickdrop_details?.ending_location_latitude || '',
    ending_location_longitude:
      jobDetail?.job_details?.pickdrop_details?.ending_location_longitude || ''
    // job_requirements: jobDetail?.job_details?.pickdrop_details?.job_requirements || '',
    // appointment_date_time: ''
  }

  useEffect(() => {
    if (jobId && !!jobDetail) {
      formik.values.id = jobDetail?.job_details?.id
      formik.values.job_description =
        jobDetail?.job_details?.job_description || ''
      formik.values.location = jobDetail?.job_details?.location
      formik.values.contact_name = jobDetail?.job_details?.contact_name || ''
      formik.values.phone_number = jobDetail?.job_details?.phone_number || ''
      formik.values.proposed_rate =
        jobDetail?.job_details?.financial_details?.proposed_rate
      formik.values.customer_priority =
        jobDetail?.job_details?.financial_details?.customer_priority
      formik.values.total_rate =
        jobDetail?.job_details?.financial_details?.total_rate
      formik.values.independent_contractor_rate =
        jobDetail?.job_details?.financial_details?.independent_contractor_rate
      // formik.values.job_requirements = jobDetail?.job_details?.pickdrop_details?.job_requirements
      formik.values.load_type =
        jobDetail?.job_details?.pickdrop_details?.load_type
      formik.values.appointment_date =
        jobDetail?.job_details?.pickdrop_details?.appointment_date
      formik.values.appointment_time =
        jobDetail?.job_details?.pickdrop_details?.appointment_time
      formik.values.appointment_number =
        jobDetail?.job_details?.pickdrop_details?.appointment_number
      formik.values.size = jobDetail?.job_details?.pickdrop_details?.size
      formik.values.is_hazmat =
        jobDetail?.job_details?.pickdrop_details?.is_hazmat
      formik.values.type_of_hazmat =
        jobDetail?.job_details?.pickdrop_details?.type_of_hazmat
      formik.values.weight = jobDetail?.job_details?.pickdrop_details?.weight
      formik.values.starting_location =
        jobDetail?.job_details?.pickdrop_details?.starting_location
      formik.values.ending_location =
        jobDetail?.job_details?.pickdrop_details?.ending_location
      formik.values.pick_up_time =
        jobDetail?.job_details?.pickdrop_details?.pick_up_time
      formik.values.scheduled_pick_up_date =
        jobDetail?.job_details?.pickdrop_details?.scheduled_pick_up_date
      formik.values.scheduled_pick_up_time =
        jobDetail?.job_details?.pickdrop_details?.scheduled_pick_up_time
      formik.values.container_number =
        jobDetail?.job_details?.pickdrop_details?.container_number
      formik.values.time_limit =
        jobDetail?.job_details?.pickdrop_details?.time_limit
      formik.values.starting_location_latitude =
        jobDetail?.job_details?.pickdrop_details?.starting_location_latitude
      formik.values.starting_location_longitude =
        jobDetail?.job_details?.pickdrop_details?.starting_location_longitude
      formik.values.ending_location_latitude =
        jobDetail?.job_details?.pickdrop_details?.ending_location_latitude
      formik.values.ending_location_longitude =
        jobDetail?.job_details?.pickdrop_details?.ending_location_longitude
    }
  }, [jobDetail])

  const userData = fetchUserData()
  const handlePayment = (values:any) => {
    // Ensure amount is always a number
    console.log('userData amount', formik.values.total_rate)
    if (formik.values.total_rate === '') {
      toast.error('Please enter a valid amount');
      return;
    }
    const amount = formik.values.total_rate ? Number(formik.values.total_rate) : 0;  
    // const amount= 80;
    const makePayment = async () => {
      try {
        const response = await fetch(`${BASE_URL}bank_payment/api/v1/make-payment/`, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${userData.token}`, // Include your access token here
            'Content-Type': 'application/json' // Specify the content type as JSON
          },
          body: JSON.stringify({
            amount: amount.toString() // Convert the amount to a string
          })
        });
  
        const responseData = await response.json(); // Parse the response as JSON
  
        // Log the entire response data
        console.log("Complete Response Data:", responseData);
        setPaymentId(responseData.id);
        const pay_id=responseData.id;
        // Check the status code of the response
        if (response.status === 200 || response.status === 201) {
          toast.success("You have completed the payment process!");

          {
            jobId
              ? putJobApiTrigger(values)
                  .unwrap()
                  .then(
                    (response: {
                      message:
                        | string
                        | number
                        | boolean
                        | ReactElement<any, string | JSXElementConstructor<any>>
                        | Iterable<ReactNode>
                        | ReactPortal
                        | ((props: ToastContentProps<unknown>) => ReactNode)
                        | null
                        | undefined,
                      payment_obj?: number // Add job_id property to the response type
                    }) => {
                      toast.success(response.message)
                      formik.resetForm()
                      resetProposedRate?.()
                      setShowLocationFields(false)
                      setTimeout(() => {
                        setShowLocationFields(true)
                      }, 200)
                      if (response.payment_obj) { // Check if job_id property exists
                        handleUpdatePaymentHistory(response.payment_obj,null,true,pay_id)
                      }
                    }
                  )
                  .catch((e: any) => {})
              : postJobApiTrigger(values)
                  .unwrap()
                  .then(
                    (response: {
                      message:
                        | string
                        | number
                        | boolean
                        | ReactElement<any, string | JSXElementConstructor<any>>
                        | Iterable<ReactNode>
                        | ReactPortal
                        | ((props: ToastContentProps<unknown>) => ReactNode)
                        | null
                        | undefined,
                      job_id?: number // Add job_id property to the response type
                    }) => {
                      if (response.job_id) { // Check if job_id property exists
                        handleUpdatePaymentHistory(response.job_id,null,false,pay_id)
                      }
                      toast.success(response.message)
                      formik.resetForm()
                      resetProposedRate?.()
                      setShowLocationFields(false)
                      setTimeout(() => {
                        setShowLocationFields(true)
                      }, 200)
                      setTimeout(() => {
                        window.location.reload()
                      }, 200)
                    }
                  )
                  .catch((e: any) => {})
          }
          setPaymentStatus('processing');
        } else {
          if(responseData.error=='Insufficient funds.'){
            toast.error("Insufficient funds. Please add funds to your account!");
            setPaymentStatus('error');
            return;
          }
          toast.error("An error occurred during the payment process!");
          setPaymentStatus('error');
          return;
        }
      } catch (e) {
        console.error("An error occurred:", e);
        toast.error("An error occurred during the payment process!");
        setPaymentStatus('error');
        return
      }
    };
  
    makePayment();
  };
  
  const handleUpdatePaymentHistory = async (id:any,values:any,update:boolean,payment_id:any) => {
    console.log('update', update)
    try {
        const response = await fetch(`${BASE_URL}bank_payment/api/v1/payments/${payment_id}/`, {
          method: 'PATCH',
          headers: {
            'Authorization': `Token ${userData.token}`,
            'Content-Type': 'application/json'
          },
          body:update?JSON.stringify({"amount":formik.values.total_rate ? Number(formik.values.total_rate):0}):JSON.stringify({"job_id": id})
        })
        // const data = await response.json()
        // const key_data = Object.keys(data)[0]

        // Accessing the value using the key
        // const error_value = data[key_data][0]
        if (response.ok) {
           update && toast.success('Payment has been successfully updated!');
        
           putJobApiTrigger(values)
           .unwrap()
           .then(
             (response: {
               message:
                 | string
                 | number
                 | boolean
                 | ReactElement<any, string | JSXElementConstructor<any>>
                 | Iterable<ReactNode>
                 | ReactPortal
                 | ((props: ToastContentProps<unknown>) => ReactNode)
                 | null
                 | undefined,
               payment_obj?: number // Add job_id property to the response type
             }) => {
               toast.success(response.message)
               formik.resetForm()
               resetProposedRate?.()
               setShowLocationFields(false)
               setTimeout(() => {
                 setShowLocationFields(true)
               }, 200)
               if (response.payment_obj) { // Check if job_id property exists
                 handleUpdatePaymentHistory(0,null,true,response.payment_obj)
               }
             }
           )
           .catch((e: any) => {})
        } else {
            console.log('response', response)
            // toast.error(error_value ? error_value : 'Something went wrong')
        }
    } catch (e) {
        console.log('error', e)
    }
}

  const [showLocationFields, setShowLocationFields] = useState(true)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: postJobSchema,
    onSubmit: (values: AddJobPayload) => {
      values.visibility = visibility
      const startingLocation: LocationObject[] = JSON.parse(
        values.starting_location
      )
      const endingLocation: LocationObject[] = JSON.parse(
        values.ending_location
      )
      values.starting_location_latitude = `${
        startingLocation?.[0].latitude ?? ''
      }`
      values.starting_location_longitude = `${
        startingLocation?.[0].longitude ?? ''
      }`
      values.ending_location_latitude = `${endingLocation?.[0].latitude ?? ''}`
      values.ending_location_longitude = `${
        endingLocation?.[0].longitude ?? ''
      }`
      if (values.appointment_date !== '' && values.appointment_time !== '') {
        values.appointment_date_time = convertToISOString(
          values.appointment_date,
          values.appointment_time
        )
      } else {
        values.appointment_date_time = null
      }
      if(values.type_of_hazmat == ''){
        values.type_of_hazmat= null;
      }
      console.log('data@',jobDetail);
      jobId
          ? handleUpdatePaymentHistory(0,values,true,jobDetail?.job_details.payment_obj)
     : handlePayment(values);
  }
  })


  const [proposedRateParams, setProposedRateParams] =
    useState<JobProposedRatePayload>()
  const retrieveMileageForProposedRate = () => {
    const starting_location = JSON.parse(
      formik.values.starting_location || '{}'
    )
    const ending_location = JSON.parse(formik.values.ending_location || '{}')
    setProposedRateParams({
      starting_location_latitude: starting_location?.[0]?.latitude ?? '',
      starting_location_longitude: starting_location?.[0]?.longitude ?? '',
      ending_location_latitude: ending_location?.[0]?.latitude ?? '',
      ending_location_longitude: ending_location?.[0]?.longitude ?? '',
      scheduled_pick_up_date: formik.values.scheduled_pick_up_date ?? '',
      scheduled_pick_up_time: formik.values.scheduled_pick_up_time ?? '',
      time_limit: parseInt(formik.values.time_limit || '0'),
      customer_priority: parseFloat(
        refCustomerPriority.current
          ? refCustomerPriority.current.value
          : formik.values.customer_priority || '0'
      ),
      mileage: null
    })
  }
 

  const handlePaymentUpdate=()=>{
    nav(`${ROUTE_NAMES.dashboardChildren.settings}`,{
      state: {
        update_payment: true,
      }
    })
  }

  const fetchData = async () => {
    try {
      const fetchResponse = await fetch(`${BASE_URL}bank_payment/api/v1/bank-account/`, {
        method: 'GET',
        headers: {
          'Authorization': `Token ${userData.token}` // Include your access token here
        }
      });
      const data = await fetchResponse.json();
      console.log("#$Data....", data);
      setHistoryId(data.id);
      setBankDetails(data); // Set the bank details
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
useEffect(() => {
  fetchData();
}, []);

  const [mileageWithinLimits, setMileageWithinLimits] = useState(true)
  useEffect(() => {
    if (!!proposedRateParams) {
      if (!!proposedRateParams?.mileage) {
        console.log(`if(!!proposedRateParams?.mileage) {`)
        if (
          `${Math.ceil(proposedRateParams.mileage)}`.length >
          APPCONFIG.mileageMaxDigits
        ) {
          setMileageWithinLimits(false)
          toast.error(
            `The start and end locations are too far apart. Please enter the locations within a range of ${Math.pow(
              10,
              APPCONFIG.mileageMaxDigits
            )} miles.`,
            { autoClose: false }
          )
        } else {
          setMileageWithinLimits(true)
          if (
            proposedRateParams.starting_location_latitude &&
            proposedRateParams.starting_location_longitude &&
            proposedRateParams.ending_location_latitude &&
            proposedRateParams.ending_location_longitude &&
            proposedRateParams.scheduled_pick_up_date &&
            proposedRateParams.scheduled_pick_up_time &&
            proposedRateParams.time_limit &&
            proposedRateParams.mileage
          ) {
            proposedRateTrigger({ ...proposedRateParams })
          } else {
          }
        }
      } else {
        console.log(`ELSE FOR if(!!proposedRateParams?.mileage) {`)
        if (
          !proposedRateParams.starting_location_latitude ||
          !proposedRateParams.ending_location_latitude
        ) {
          toast.error(
            'Please fill all the fields under the `Pick/Drop Details` section'
          )
        } else {
          setRouteDistanceParams({
            origin: `${proposedRateParams.starting_location_latitude},${proposedRateParams.starting_location_longitude}`,
            destination: `${proposedRateParams.ending_location_latitude},${proposedRateParams.ending_location_longitude}`,
            modes: routeDistanceParams.modes
          })
        }
      }
    } else {
      console.log('NOT GOOD - proposedRateParams')
    }
  }, [proposedRateParams])

  useEffect(() => {
    if (!!dataRouteDistance && !isLoadingRouteDistance) {
      const distanceInMiles = ftToMiles(
        dataRouteDistance?.routes?.car?.distance?.value ?? 0
      )
      setProposedRateParams({
        ...proposedRateParams,
        mileage: Number(distanceInMiles.toFixed(1))
      })
      setRouteDistanceParams({
        origin: '',
        destination: '',
        modes: 'car'
      })
    }
  }, [dataRouteDistance, isLoadingRouteDistance])

  useEffect(() => {
    if (!!dataProposedRate && !isLoadingProposedRate) {
      formik.setFieldValue(
        'proposed_rate',
        dataProposedRate?.financial_detail?.selling_rate
      )
      formik.setFieldValue(
        'total_rate',
        dataProposedRate?.financial_detail?.total_rate
      )
      formik.setFieldValue(
        'independent_contractor_rate',
        dataProposedRate?.financial_detail?.independent_contractor_rate
      )
    }
  }, [dataProposedRate, isLoadingProposedRate])

  return (
    <FormikProvider value={formik}>
      {(isLoading || isLoadingPutJobApi || isLoadingProposedRate || isLoadingRouteDistance ) && (
        <Loader />
      )}
      <Form className="form-add-job" onSubmit={formik.handleSubmit}>
        <Container fluid className="containerWidth">
          <Row>
            <div className="d-flex flex-row justify-content-between">
              <h1 className="page-title">
                {jobId ? `Edit Job #${jobId}` : `Add Job`}
              </h1>
              <PrimaryButton
                title={jobId ? 'Pay and Update Job' : 'Pay and Post Job'}
                className="mt-5"
                type="submit"
                disabled={
                  !dataProposedRate ||
                  isLoading ||
                  isLoadingRouteDistance ||
                  !mileageWithinLimits
                }
              />
            </div>
          </Row>
          <Row className="mt-3">
            <Col md={4}>
              <CardWithHeader title={'General Details'}>
                <div>
                  <FormControl
                    placeholder="Job Description"
                    controlId="validateJobDes"
                    onChange={e =>
                      formik.setFieldValue('job_description', e.target.value)
                    }
                    errorMessage={formik.errors.job_description}
                    value={formik.values.job_description}
                    isInvalid={!!formik.errors.job_description}
                    as={'textarea'}
                    showLabel={true}
                  />
                  {/* <FormControl
                    placeholder="Location"
                    controlId="validateLocation"
                    onChange={e =>
                      formik.setFieldValue("location", e.target.value)
                    }
                    errorMessage={formik.errors.location}
                    value={formik.values.location}
                    isInvalid={!!formik.errors.location}
                  /> */}
                </div>
              </CardWithHeader>

              <CardWithHeader title={'Contact Details'}>
                <div>
                  <FormControl
                    placeholder="Contact Person"
                    controlId="validateContactPerson"
                    onChange={e =>
                      formik.setFieldValue('contact_name', e.target.value)
                    }
                    errorMessage={formik.errors.contact_name}
                    value={formik.values.contact_name}
                    isInvalid={!!formik.errors.contact_name}
                    showLabel={true}
                  />
                  <FormControl
                    type="number"
                    placeholder="Phone"
                    controlId="validatePhone"
                    onChange={e =>
                      formik.setFieldValue('phone_number', e.target.value)
                    }
                    errorMessage={formik.errors.phone_number}
                    value={formik.values.phone_number}
                    isInvalid={!!formik.errors.phone_number}
                    showLabel={true}
                  />
                </div>
              </CardWithHeader>
            </Col>
            <Col md={4}>
              <CardWithHeader title={'Pick/Drop Details'}>
                <div>
                  {/* <FormControl
                    placeholder="Job Requirements"
                    controlId="validateJobRequirement"
                    onChange={e =>
                      formik.setFieldValue('job_requirements', e.target.value)
                    }
                    errorMessage={formik.errors.job_requirements}
                    value={formik.values.job_requirements}
                    isInvalid={!!formik.errors.job_requirements}
                    showLabel={true}
                  /> */}

                  <FormSelect
                    placeholder="Type of Load"
                    controlId="validateloadType"
                    onChange={e =>
                      formik.setFieldValue('load_type', e.target.value)
                    }
                    errorMessage={formik.errors.load_type}
                    value={formik.values.load_type}
                    isInvalid={!!formik.errors.load_type}
                    showLabel={true}
                  >
                    {loadtype?.map(ele => {
                      return <option value={ele.id}>{ele.name}</option>
                    })}
                  </FormSelect>

                  <FormControl
                    placeholder="Weight (lbs)"
                    controlId="validateWeight"
                    onChange={e => {
                      const inputValue = e.target.value
                      // Use a regex to allow only numeric input
                      if (/^\d*$/.test(inputValue)) {
                        formik.setFieldValue('weight', inputValue)
                      }
                    }}
                    errorMessage={formik.errors.weight}
                    value={formik.values.weight}
                    isInvalid={!!formik.errors.weight}
                    showLabel={true}
                  />

                  <Form.Group
                    className="form-field form-field-- col-sm-12 position-relative"
                    controlId="is_hazmat"
                  >
                    <Form.Label className="text-primary">Hazmat</Form.Label>

                    <Form.Check
                      type="checkbox"
                      id="is_hazmat"
                      label="Hazmat"
                      value="true"
                      onChange={e => {
                        if (formik.values.is_hazmat === true) {
                          formik.setFieldValue('is_hazmat', false)
                        } else {
                          formik.setFieldValue('is_hazmat', true)
                        }
                      }}
                      checked={formik.values.is_hazmat}
                    />
                  </Form.Group>

                  <FormSelect
                    placeholder="Type of Hazmat"
                    controlId="validateTypeOfHazmat"
                    onChange={e =>
                      formik.setFieldValue(
                        'type_of_hazmat',
                        parseInt(e.target.value)
                      )
                    }
                    errorMessage={formik.errors.type_of_hazmat}
                    value={formik.values.type_of_hazmat == null ? '' : formik.values.type_of_hazmat}
                    isInvalid={!!formik.errors.type_of_hazmat}
                    showLabel={true}
                    disabled={!formik.values.is_hazmat}
                  >
                    {getHazMatType?.map(ele => {
                      return (
                        <option value={ele.id} key={ele.id}>
                          {ele.name}
                        </option>
                      )
                    })}
                  </FormSelect>

                  <FormSelect
                    placeholder="Size (feet)"
                    controlId="validateSize"
                    onChange={e => formik.setFieldValue('size', e.target.value)}
                    errorMessage={formik.errors.size}
                    value={formik.values.size}
                    isInvalid={!!formik.errors.size}
                    showLabel={true}
                  >
                    {getSize?.map(ele => {
                      return <option value={ele.id}>{ele.name}</option>
                    })}
                  </FormSelect>

                  <FormControl
                    type="date"
                    placeholder="Appointment Date"
                    controlId="validateAppDate"
                    onChange={e =>
                      formik.setFieldValue('appointment_date', e.target.value)
                    }
                    errorMessage={formik.errors.appointment_date}
                    value={formik.values.appointment_date}
                    isInvalid={!!formik.errors.appointment_date}
                    min={new Date().toISOString().split('T')[0]}
                    showLabel={true}
                  />

                  <FormControl
                    type="time"
                    placeholder="Appointment Time"
                    controlId="validateAppTime"
                    onChange={e =>
                      formik.setFieldValue('appointment_time', e.target.value)
                    }
                    errorMessage={formik.errors.appointment_time}
                    value={formik.values.appointment_time}
                    isInvalid={!!formik.errors.appointment_time}
                    showLabel={true}
                  />

                  <FormControl
                    placeholder="Appointment Number"
                    controlId="validateAppNumber"
                    onChange={e => {
                      const inputValue = e.target.value
                      // Use a regex to allow only numeric input
                      if (/^\d*$/.test(inputValue)) {
                        formik.setFieldValue('appointment_number', inputValue)
                      }
                    }}
                    errorMessage={formik.errors.appointment_number}
                    value={formik.values.appointment_number}
                    isInvalid={!!formik.errors.appointment_number}
                    showLabel={true}
                  />

                  <FormControl
                    type={showLocationFields ? 'locationAutocomplete' : 'text'}
                    onTypeaheadChange={(selectedLocation: any) => {
                      formik.setFieldValue(
                        'starting_location',
                        JSON.stringify(selectedLocation)
                      )
                      if (selectedLocation !== undefined) setStartError('')
                      else {
                        setStartError('Starting Location is required')
                      }
                      toast.dismiss()
                      resetProposedRate?.()
                      formik.setFieldValue('proposed_rate', '')
                      formik.setFieldValue('total_rate', '')
                    }}
                    placeholder="Starting Location"
                    value={formik.values.starting_location}
                    // errorMessage={showStartError}
                    errorMessage={
                      showStartError !== '' && formik.errors.starting_location
                        ? formik.errors.starting_location
                        : ''
                    }
                    // isInvalid={!!formik.errors.starting_location}
                    isInvalid={
                      showStartError !== '' && formik.errors.starting_location
                        ? true
                        : false
                    }
                    showLabel={true}
                  />
                  <FormControl
                    type={showLocationFields ? 'locationAutocomplete' : 'text'}
                    onTypeaheadChange={(selectedLocation: any) => {
                      formik.setFieldValue(
                        'ending_location',
                        JSON.stringify(selectedLocation)
                      )
                      if (selectedLocation !== undefined) setEndError('')
                      else {
                        setEndError('Starting Location is required')
                      }
                      toast.dismiss()
                      resetProposedRate?.()
                      formik.setFieldValue('proposed_rate', '')
                      formik.setFieldValue('total_rate', '')
                    }}
                    placeholder="End Location"
                    value={formik.values.ending_location}
                    errorMessage={
                      showEndError !== '' && formik.errors.ending_location
                        ? formik.errors.ending_location
                        : ''
                    }
                    // isInvalid={!!formik.errors.ending_location}
                    isInvalid={
                      showEndError !== '' && formik.errors.ending_location
                        ? true
                        : false
                    }
                    showLabel={true}
                  />
                  {/* <GooglePlacesAutocomplete apiKey="****" /> */}

                  <FormControl
                    type="date"
                    placeholder="Scheduled pick-up date"
                    controlId="validateScheduledDate"
                    onChange={e =>
                      formik.setFieldValue(
                        'scheduled_pick_up_date',
                        e.target.value
                      )
                    }
                    errorMessage={formik.errors.scheduled_pick_up_date}
                    value={formik.values.scheduled_pick_up_date}
                    isInvalid={!!formik.errors.scheduled_pick_up_date}
                    min={new Date().toISOString().split('T')[0]}
                    showLabel={true}
                  />
                  <FormControl
                    type="time"
                    placeholder="Scheduled pick-up time"
                    controlId="validateScheduledTime"
                    onChange={e =>
                      formik.setFieldValue(
                        'scheduled_pick_up_time',
                        e.target.value
                      )
                    }
                    errorMessage={formik.errors.scheduled_pick_up_time}
                    value={formik.values.scheduled_pick_up_time}
                    isInvalid={!!formik.errors.scheduled_pick_up_time}
                    showLabel={true}
                  />
                  <FormControl
                    placeholder="Container Number"
                    controlId="validateContainerNumber"
                    onChange={e =>
                      formik.setFieldValue('container_number', e.target.value)
                    }
                    errorMessage={formik.errors.container_number}
                    value={formik.values.container_number}
                    isInvalid={!!formik.errors.container_number}
                    showLabel={true}
                  />
                  <div className="time-limit-tooltip">
                    <FormControl
                      type="number"
                      placeholder="Time Limit (hr)"
                      controlId="validateTimeLimit"
                      onChange={e =>
                        formik.setFieldValue('time_limit', e.target.value)
                      }
                      errorMessage={formik.errors.time_limit}
                      value={formik.values.time_limit}
                      isInvalid={!!formik.errors.time_limit}
                      showLabel={true}
                    />
                    <BottomTooltip
                      tooltipText="Job will expire after this time limit if no one has picked up this job."
                      className="addjobs-tooltip"
                    />
                  </div>
                </div>
              </CardWithHeader>

              <CardWithHeader title={'Visibility'}>
                <div>
                  <Form.Check
                    label="Visible for all"
                    name="jobVisibility"
                    type={'radio'}
                    id={'visibleForAll'}
                    className="mt-3"
                    defaultChecked={true}
                    onChange={() => setVisibility(1)}
                  />
                  <Form.Check
                    label="Visible for company drivers"
                    name="jobVisibility"
                    type={'radio'}
                    id={`visibleForCompanyDriver`}
                    className="mt-3"
                    onChange={() => setVisibility(2)}
                  />
                  <Form.Check
                    label="Visible for non-company drivers"
                    name="jobVisibility"
                    type={'radio'}
                    id={`visibleForNonCompany`}
                    className="mt-3"
                    onChange={() => setVisibility(3)}
                  />
                </div>
              </CardWithHeader>
            </Col>

            <Col md={4} >
              <CardWithHeader title={'Financial Details'}>
                <div>
                  <FormControl
                    type="text"
                    label="Proposed Rate ($)"
                    placeholder="..."
                    controlId="validateProposedRate"
                    onChange={e =>
                      formik.setFieldValue('proposed_rate', e.target.value)
                    }
                    errorMessage={formik.errors.proposed_rate}
                    value={formik.values.proposed_rate}
                    isInvalid={!!formik.errors.proposed_rate}
                    showLabel={true}
                    readOnly={true}
                    fieldPreText={`$`}
                    // fieldInfoText="Please fill details under the 'Pick/Drop Details' section"
                  />
                  <FormControl
                    type="number"
                    label="Customer Priority ($)"
                    placeholder="Please enter a value"
                    controlId="validateCustomerPriority"
                    onKeyUp={e => {
                      resetProposedRate?.()
                      formik.setFieldValue(
                        'customer_priority',
                        (e.target as HTMLInputElement).value
                      )
                    }}
                    onChange={e => {
                      resetProposedRate?.()
                      formik.setFieldValue(
                        'total_rate',
                        dataProposedRate?.financial_detail?.total_rate
                      )
                      formik.setFieldValue('customer_priority', e.target.value)
                    }}
                    errorMessage={formik.errors.customer_priority}
                    value={formik.values.customer_priority}
                    isInvalid={!!formik.errors.customer_priority}
                    showLabel={true}
                    fieldPreText={`$`}
                    ref={refCustomerPriority} // causing errors
                  />
                </div>
                <div className="sub-total d-flex align-items-center justify-content-between my-4">
                  <span className="flex-1">Sub Total</span>
                  <span className="field-sub-total text-right not-w-50">
                    {formik.values.total_rate
                      ? `$${formik.values.total_rate}`
                      : '...'}
                    {/* <FormControl
                      wrapperClassName={``}
                      className="field-sub-total text-right"
                      fieldClassName={`mt-0`}
                      placeholder="..."
                      controlId="validateTotalRate"
                      errorMessage={formik.errors.total_rate}
                      value={formik.values.total_rate}
                      isInvalid={!!formik.errors.total_rate}
                      readOnly={true}
                      plaintext={true}
                      fieldPreText={`$`}
                    /> */}
                  </span>
                </div>
                <div className="d-flex justify-content-end">
                  <PrimaryButton
                    title="Calculate"
                    className="mt-0 mb-2"
                    disabled={paymentStatus=='processing' && true}
                    onClick={retrieveMileageForProposedRate}
                  />
                </div>
              </CardWithHeader>

              {
                paymentStatus=='processing'?
                 <CardWithHeader title={'Payment'}
              wrapperClassName='payment'
              // backgroundColorName={'red'}
              >
                <div className='payment_hold_status'>
                  <InfoNewIcon/>
                  <span>Payment on hold till job delivery is completed</span>
                </div>
                </CardWithHeader>
               : 
                <CardWithHeader
                  title={'Payment'}
                  backgroundColorName={paymentStatus == 'error' ? 'red' : undefined}
                >
                <div>
                  <FormControl
                    type="text"
                    label="Sub Total ($)"
                    placeholder="Sub total"
                    controlId="validateProposedRate"
                    // onChange={e =>
                    //   formik.setFieldValue('proposed_rate', e.target.value)
                    // }
                    // errorMessage={formik.errors.proposed_rate}
                    value={formik.values.total_rate}
                    // isInvalid={!!formik.errors.proposed_rate}
                    showLabel={true}
                    readOnly={true}
                    fieldPreText={`$`}
                    // fieldInfoText="Please fill details under the 'Pick/Drop Details' section"
                  />
                <FormControl
                    type="text"
                    placeholder='Bank Account Number'
                    // placeholderOptionText={bankDetails?.last4 !=undefined? `XXXXXXXX${bankDetails?.last4}` : 'Bank Account Number'}
                    controlId="validateloadType"
                    // onChange={e =>
                    //   formik.setFieldValue('load_type', e.target.value)
                    // }
                    // errorMessage={formik.errors.load_type}
                    value={bankDetails?.last4 !=undefined? `XXXXXXXX${bankDetails?.last4}` : 'Bank Account Number'}
                    // isInvalid={!!formik.errors.load_type}
                    disabled={bankDetails?.last4 ? true : false}
                    showLabel={true}
                  >
            
                  </FormControl>
                 { paymentStatus=='error' && <div style={{display:'flex',justifyContent:'center',fontSize:'10px'}}>                    
                  <span style={{color:'red'}}>
                  <InfoNewIcon color={'red'} />
                    Payment details not updated.</span>
                  <a href='' style={{paddingTop:'2px'}}
                  onClick={handlePaymentUpdate}
                  >Click here to update</a>
                  </div>}
                </div>
               
                {/* <div className="d-flex justify-content-end mt-2">
                  <PrimaryButton
                    title="Pay"
                    className="mt-0 mb-2"
                    // disabled={paymentStatus=='error' && true}
                    onClick={handlePayment}
                  />
                </div> */}
              </CardWithHeader>}

             
            </Col>


            
          </Row>
        </Container>
      </Form>
    </FormikProvider>
  )
}

export default PageAddJobs
