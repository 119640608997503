import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  API_USER_URL_SUFFIX,
  BASE_URL,
  USER_ACCOUNT_INFO,
  BANK_ACCOUNT,
  PAYMENT_CREATE_LINK_TOKEN,
  MAKE_PAYMENT,
  PAYMENT_HISTORY,
  DISCONNECT_BANK,
  VERIFY_BANK,
  PAY_OUT_VERIFY
} from './ServerUrl'
import { fetchUserData } from '../../utils/storage'
import { handleAuthErrorCode } from './errorCodes'
import { PaymentAmountPayload, PaymentDetailsPayload, PaymentHistoryPayload, PaymentMethodsPayload } from '../types/Payments'
import { GenericPagingResponse, UserQuery } from '../types/Driver'

export enum PaymentAPITags {
  BANK_DETAIL = 'BANK_DETAILS',
  // APPROVE_DOCUMENT = 'APPROVE_DOCUMENT',
  UPDATE_BANK_DETAILS = 'UPDATE_BANK_DETAILS'
}
export const PaymentAPIS = createApi({
  reducerPath: 'paymentAPIS',
  keepUnusedDataFor: 0,
  tagTypes: [PaymentAPITags.BANK_DETAIL, PaymentAPITags.UPDATE_BANK_DETAILS],
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: async headers => {
      if (!headers.has('Content-Type')) {
        headers.set('Content-Type', 'application/json')
      }
      const userData = fetchUserData()
      headers.set('Authorization', `Token ${userData.token}`)

      return headers
    }
  }),
  endpoints: builder => ({
    accountInfo: builder.query<PaymentMethodsPayload, any>({
      query: () => {
        return {
          url: `${API_USER_URL_SUFFIX}${USER_ACCOUNT_INFO}`
        }
      },
      transformErrorResponse(baseQueryReturnValue) {
        handleAuthErrorCode(baseQueryReturnValue)
      },
      // providesTags: [UserAPITags.USER_DETAIL, UserAPITags.UPDATE_USER],
      transformResponse: (response: PaymentMethodsPayload) => {
        return response
      }
    }),

    postBankDetails: builder.mutation<any, PaymentDetailsPayload>({
      query: (params: PaymentDetailsPayload) => {
        return {
          url: BANK_ACCOUNT,
          method: 'POST',
          body: params
        }
      },
      transformErrorResponse(baseQueryReturnValue) {
        handleAuthErrorCode(baseQueryReturnValue)
      },
      transformResponse: async (data: Response) => {
        return data
      }
    }),

    createPaymentLink: builder.mutation<Response, void>({
        query: () => {
            return {
                url: PAYMENT_CREATE_LINK_TOKEN,
                method: 'POST'
            }
        },
        transformErrorResponse(baseQueryReturnValue) {
            handleAuthErrorCode(baseQueryReturnValue)
        },
        transformResponse: async (data: Response) => {
            // Process the response here if needed
            return data;
        }
    }),

        getBankDetail: builder.query<Response, void>({
          query: () => ({
            url: BANK_ACCOUNT,
            method: 'GET'
          }),
          transformResponse: (response: any) => response, // Identity transform
          transformErrorResponse: (error: any) => {
            handleAuthErrorCode(error);
            return error;
          }
        }),

        postMakePayment: builder.mutation<any, PaymentAmountPayload>({
          query: (params: PaymentAmountPayload) => { // Change the type of 'params' to 'PaymentAmountPayload'
            return {
              url: MAKE_PAYMENT,
              method: 'POST',
              body: params
            }
          },
          transformErrorResponse(baseQueryReturnValue) {
            handleAuthErrorCode(baseQueryReturnValue)
          },
          transformResponse: async (data: Response) => {
            return data
          }
        }),

        allPaymentHistory: builder.query<GenericPagingResponse<any>, UserQuery>({
          query: (q: UserQuery) => {
            return {
              url: `${PAYMENT_HISTORY}`,
              params: q
            }
          },
          transformErrorResponse(baseQueryReturnValue) {
            handleAuthErrorCode(baseQueryReturnValue)
          },
          providesTags: [PaymentAPITags.BANK_DETAIL, PaymentAPITags.UPDATE_BANK_DETAILS],
          transformResponse: (response: GenericPagingResponse<PaymentHistoryPayload>) => {
            return response
          }
        }),

        postDisconnectBankAccount: builder.mutation<void, string>({
          query: (bank_account_id: string) => ({
            url: `${DISCONNECT_BANK}`,
            method: 'POST',
            body: { 'bank_account_id':bank_account_id }
          }),
          transformResponse: (response: any) => response,
          transformErrorResponse(baseQueryReturnValue) {
            // handleAuthErrorCode(baseQueryReturnValue)
          },
          invalidatesTags: [PaymentAPITags.BANK_DETAIL, PaymentAPITags.UPDATE_BANK_DETAILS],
        }),

        postVerifyBankAccount: builder.mutation<any,any>({
          query: () => {
            return {
              url: VERIFY_BANK,
              method: 'POST',
            }
          },
          transformErrorResponse(baseQueryReturnValue) {
            handleAuthErrorCode(baseQueryReturnValue)
          },
          transformResponse: async (data: any) => {
            return data
          }
        }),

        getPayOutVerify: builder.query<any, void>({
          query: () => {
            return {
              url: PAY_OUT_VERIFY,
              method: 'GET'
            }
          },
          transformErrorResponse(baseQueryReturnValue) {
            handleAuthErrorCode(baseQueryReturnValue)
          },
          transformResponse: async (data: any) => {
            return data
          }

      })
    })
  })


export const {
  useAccountInfoQuery,
  usePostBankDetailsMutation,
  useCreatePaymentLinkMutation,
  useGetBankDetailQuery,
  usePostMakePaymentMutation,
  useLazyAllPaymentHistoryQuery,
  usePostDisconnectBankAccountMutation,
  usePostVerifyBankAccountMutation,
  useGetPayOutVerifyQuery
} = PaymentAPIS
