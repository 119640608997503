import {
  ButtonGroup,
  Col,
  Container,
  Form,
  Row,
  ToggleButton
} from 'react-bootstrap'
import { FormikProvider, useFormik } from 'formik'
import FormControl from '../../../components/formControl/FormControl'
import { addDriverSchema } from '../../../utils/Validations'
import CardWithHeader from '../../../components/cardWithHeader/CardWithHeader'
import FormSelect from '../../../components/formSelect/FormSelect'
import PageActionsBar from '../../../layout/dashboardLayout/pageActionsBar'
import PrimaryButton from '../../../components/primaryButton/PrimaryButton'
import { IAddUser } from '../add-user/types'
import {
  useAddUserMutation,
  useFetchDriverTypeQuery,
  useFetchVehicleTypeQuery
} from '../../../api/network/userApiService'
import { AddUserPayload, IDNameResponse } from '../../../api/types/Driver'
import { userTypes, localStorageKeys } from '../../../utils/constants'
import Loader from '../../../components/loader/Loader'
import { toast } from 'react-toastify'
import { fetchUserType } from '../../../utils/storage'
import DetailHeader from '../../jobs/jobDetail/header/header'

const PageAddDriver = (props: IAddUser) => {
  const { data: driverTypes } = useFetchDriverTypeQuery()
  const { data: vehicleTypes } = useFetchVehicleTypeQuery()
  const [addUserApiTrigger, { isLoading }] = useAddUserMutation()

  const userStorageData = localStorage?.getItem('user')
  const user = userStorageData
    ? JSON.parse(userStorageData).user.user_type
    : null

  const { userType, setUserType } = props
  console.log('usertype', userType)
  const initialValues: AddUserPayload = {
    name: '',
    email: '',
    confirmEmail: '',
    phone_number: '',
    // truck_driver_type: "",
    license_number: '',
    valid_till: '',
    // vehicle_type: "",
    // license_plate: "",
    street_address: '',
    city: '',
    zip_code: '',
    country: '',
    state: '',
    user_type: Number(userType)
  }
  const formik = useFormik({
    initialValues,
    validationSchema: addDriverSchema,
    onSubmit: (values: AddUserPayload) => {
      addUserApiTrigger(values)
        .unwrap()
        .then(response => {
          toast.success(response.message)
          formik.resetForm()
        })
        .catch(e => {})
    }
  })

  const chips = [
    { name: 'Driver', value: userTypes.DRIVER },
    { name: 'Company', value: userTypes.COMPANY }
  ]

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <Container fluid className="containerWidth">
          {isLoading && <Loader />}
          <Row>
            <Col>
              <DetailHeader
                title={
                  fetchUserType() === userTypes.ADMIN
                    ? 'Add User'
                    : 'Add Driver'
                }
                className={
                  fetchUserType() === userTypes.ADMIN ? 'mb-0' : 'mb-2'
                }
                {...(fetchUserType() === userTypes.COMPANY
                  ? {
                      CtaComponent: (
                        <PrimaryButton title="Create" type="submit" />
                      )
                    }
                  : {})}
              />
              {fetchUserType() === userTypes.ADMIN && (
                <PageActionsBar
                  wrapperClassName={`mt-4`}
                  ContentLeft={() => (
                    <>
                      <ButtonGroup>
                        {chips.map((radio, i) => (
                          <ToggleButton
                            key={i}
                            id={`radio-${i}`}
                            type="checkbox"
                            variant="outline-primary"
                            value={radio.value}
                            checked={userType === radio.value}
                            onChange={e => setUserType(e.currentTarget.value)}
                            className={`tab ${
                              userType === radio.value
                                ? 'tabSelected'
                                : 'tabUnSelected'
                            }`}
                          >
                            {radio.name}
                          </ToggleButton>
                        ))}
                      </ButtonGroup>
                    </>
                  )}
                  ContentRight={() => (
                    <div>
                      <PrimaryButton title="Create" type="submit" />
                    </div>
                  )}
                />
              )}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={4}>
              <CardWithHeader title={'Personal Details'}>
                <div>
                  <FormControl
                    placeholder="Full Name"
                    controlId="validateName"
                    onChange={e => formik.setFieldValue('name', e.target.value)}
                    errorMessage={formik.errors.name}
                    value={formik.values.name}
                    isInvalid={!!formik.errors.name}
                    showLabel={true}
                  />
                  <FormControl
                    type="email"
                    placeholder="Email"
                    controlId="validateEmail"
                    onChange={e =>
                      formik.setFieldValue('email', e.target.value)
                    }
                    errorMessage={formik.errors.email}
                    value={formik.values.email}
                    isInvalid={!!formik.errors.email}
                    showLabel={true}
                  />
                  <FormControl
                    type="email"
                    placeholder="Confirm Email"
                    controlId="validateEmail"
                    onChange={e =>
                      formik.setFieldValue('confirmEmail', e.target.value)
                    }
                    errorMessage={formik.errors.confirmEmail}
                    value={formik.values.confirmEmail}
                    isInvalid={!!formik.errors.confirmEmail}
                    showLabel={true}
                  />
                  <FormControl
                    type="number"
                    placeholder="Phone"
                    controlId="validatePhone"
                    onChange={e =>
                      formik.setFieldValue('phone_number', e.target.value)
                    }
                    errorMessage={formik.errors.phone_number}
                    value={formik.values.phone_number}
                    isInvalid={!!formik.errors.phone_number}
                    showLabel={true}
                  />
                </div>
              </CardWithHeader>
            </Col>
            <Col md={4}>
              <CardWithHeader
                title={
                  fetchUserType() === userTypes.ADMIN
                    ? 'Driver Details'
                    : // : 'Vehicle Details'
                      'Driver Details'
                }
              >
                <div>
                  {/* <FormSelect
                    placeholder="Truck Driver Type"
                    controlId="validateDriverType"
                    onChange={e =>
                      formik.setFieldValue("truck_driver_type", e.target.value)
                    }
                    errorMessage={formik.errors.truck_driver_type}
                    value={formik.values.truck_driver_type}
                    isInvalid={!!formik.errors.truck_driver_type}
                    showLabel={true}
                  >
                    {driverTypes?.map((ele: IDNameResponse) => {
                      return <option value={ele.id}>{ele.name}</option>
                    })}
                  </FormSelect> */}
                  <FormControl
                    placeholder="License Number"
                    controlId="validateLicenseNumber"
                    onChange={e =>
                      formik.setFieldValue('license_number', e.target.value)
                    }
                    errorMessage={formik.errors.license_number}
                    value={formik.values.license_number}
                    isInvalid={!!formik.errors.license_number}
                    showLabel={true}
                  />
                  <FormControl
                    type="date"
                    placeholder="Valid Till"
                    controlId="validateValidTill"
                    onChange={e =>
                      formik.setFieldValue('valid_till', e.target.value)
                    }
                    errorMessage={formik.errors.valid_till}
                    value={formik.values.valid_till}
                    isInvalid={!!formik.errors.valid_till}
                    min={new Date().toISOString().split('T')[0]}
                    showLabel={true}
                  />

                  {/* <FormSelect
                    placeholder="Vehicle Type"
                    controlId="validateVehicleType"
                    onChange={e =>
                      formik.setFieldValue("vehicle_type", e.target.value)
                    }
                    errorMessage={formik.errors.vehicle_type}
                    value={formik.values.vehicle_type}
                    isInvalid={!!formik.errors.vehicle_type}
                    showLabel={true}
                  >
                    {vehicleTypes?.map((ele: IDNameResponse) => {
                      return <option value={ele.id}>{ele.name}</option>
                    })}
                  </FormSelect> */}
                  {/* <FormControl
                    placeholder="License Plate"
                    controlId="validateLicensePlate"
                    onChange={e =>
                      formik.setFieldValue("license_plate", e.target.value)
                    }
                    errorMessage={formik.errors.license_plate}
                    value={formik.values.license_plate}
                    isInvalid={!!formik.errors.license_plate}
                    showLabel={true}
                  /> */}
                </div>
              </CardWithHeader>
            </Col>
            <Col md={4}>
              <CardWithHeader title="Address Details">
                <FormControl
                  placeholder="Address"
                  controlId="validateAddress"
                  onChange={e =>
                    formik.setFieldValue('street_address', e.target.value)
                  }
                  errorMessage={formik.errors.street_address}
                  value={formik.values.street_address}
                  isInvalid={!!formik.errors.street_address}
                  showLabel={true}
                />
                <FormControl
                  placeholder="City"
                  controlId="validateCity"
                  onChange={e => formik.setFieldValue('city', e.target.value)}
                  errorMessage={formik.errors.city}
                  value={formik.values.city}
                  isInvalid={!!formik.errors.city}
                  showLabel={true}
                />
                <FormControl
                  placeholder="Zip Code"
                  controlId="validateZip"
                  onChange={e =>
                    formik.setFieldValue('zip_code', e.target.value)
                  }
                  errorMessage={formik.errors.zip_code}
                  value={formik.values.zip_code}
                  isInvalid={!!formik.errors.zip_code}
                  showLabel={true}
                />
                <FormControl
                  placeholder="State"
                  controlId="validateState"
                  onChange={e => formik.setFieldValue('state', e.target.value)}
                  errorMessage={formik.errors.state}
                  value={formik.values.state}
                  isInvalid={!!formik.errors.state}
                  showLabel={true}
                />
                <FormControl
                  placeholder="Country"
                  controlId="validateCountry"
                  onChange={e =>
                    formik.setFieldValue('country', e.target.value)
                  }
                  errorMessage={formik.errors.country}
                  value={formik.values.country}
                  isInvalid={!!formik.errors.country}
                  showLabel={true}
                />
              </CardWithHeader>
            </Col>
          </Row>
        </Container>
      </Form>
    </FormikProvider>
  )
}

export default PageAddDriver
