import { useEffect, useState } from "react"
import PageAddDriver from "../add-driver/add-driver"
import PageAddCompany from "../add-company/add-company"
import { userTypes } from "../../../utils/constants"
import { fetchUserData } from "../../../utils/storage"
 
const PageAddUser = () => {
  const userData = fetchUserData()
  useEffect(() => {
   fetchUserData()
   console.log(userData);
  }, [])
  const chips = [
    { name: "Driver", value: userTypes.DRIVER },
    { name: "Company", value:userTypes.COMPANY }
  ]
  const [radioValue, setRadioValue] = useState(userData?.user?.user_type !='1'?chips[0].value:chips[1].value);
 
  return radioValue ===   userTypes.DRIVER && userData?.user?.user_type !='1' ? (
<PageAddDriver userType={radioValue} setUserType={setRadioValue} cardHeader={""}/>
  ) : (
<PageAddCompany userType={radioValue} setUserType={setRadioValue} cardHeader={""}/>
  )
}
 
export default PageAddUser
