import Button from "react-bootstrap/esm/Button"
import { IPrimaryButton } from "./types"

const PrimaryButton = (props: IPrimaryButton) => {
  const { title, size = "lg",color } = props
  return (
    <Button {...props} size={size} color={color}>
      {title}
    </Button>
  )
}
export default PrimaryButton
