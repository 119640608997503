import { Button, Col, Container, Row } from 'react-bootstrap'
import { ISettingItem } from './types'
import PageChangePassword from './changePassword/change-password'
import { useEffect, useState } from 'react'
import './settings.scss'
import PersonIcon from '../../assets/images/svg/PersonIcon'
import LockIcon from '../../assets/images/svg/LockIcon'
import FeedbackIcon from '../../assets/images/svg/FeedbackIcon'
import UploadIcon from '../../assets/images/svg/DocUpload'
import PolicyIcon from '../../assets/images/svg/PolicyIcon'
import GavelIcon from '../../assets/images/svg/GavelIcon'
import RightArrowIcon from '../../assets/images/svg/RightArrowIcon'
import PageSendFeedback from '../dashboard/feedbacks/sendFeedback/send-feedback'
import Loader from '../../components/loader/Loader'
import EmptyPage from './emptyPage'
import PageProfileDetail from './profileDetail/profile-detail'
import NotificationIcon from '../../assets/images/svg/NotificationIcon'
import UploadDocument from '../dashboard/upload-document/upload-document'
import PaymentMethods from '../Payments/PaymentMethods/payment-methods'
import PaymentList from '../Payments/Payments/Payment-list'
import { useLocation } from 'react-router-dom'
import PaymentIcon from '../../assets/images/svg/PaymentIcon'
import PaymentMethodIcon from '../../assets/images/svg/PaymentMethodIcon'

const PageSettings = () => {
  const [loading, setLoading] = useState(false)
  const location = useLocation();
// console.log("##L",location.state.update_payment);
  const [selectedItem, setSelectedItem] = useState({
    title: 'Profile',
    page: <PageProfileDetail setLoading={setLoading} />
  })

  const iconColor = (title: string) => {
    return selectedItem.title === title ? '#00A3FF' : '#444444'
  }
  const settingItems: ISettingItem[] = [
    {
      page: <PageProfileDetail setLoading={setLoading} />,
      title: 'Profile',
      icon: <PersonIcon color={iconColor('Profile')} />
    },
    {
      page: <PaymentList  />,
      title: 'Payments',
      icon: <PaymentIcon color={iconColor('Payments')} />
    },
    {
      page: <PaymentMethods/>,
      title: 'Payment Methods',
      icon: <PaymentMethodIcon color={iconColor('Payment Methods')} />
    },
    // {
    //   page: <EmptyPage />,
    //   title: 'Notifications',
    //   icon: <NotificationIcon color={iconColor('Notifications')} />
    // },
    {
      page: <PageChangePassword setLoading={setLoading} />,
      title: 'Change Password',
      icon: <LockIcon color={iconColor('Change Password')} />
    },
    {
      page: <PageSendFeedback setLoading={setLoading} />,
      title: 'Support & Feedback',
      icon: <FeedbackIcon color={iconColor('Support & Feedback')} />
    },
    {
      page: <UploadDocument setLoading={setLoading} />,
      title: 'Documents',
      icon: <UploadIcon color={iconColor('Documents')} />
    }
    // {
    //   page: <EmptyPage />,
    //   title: "Privacy Policy",
    //   icon: <PolicyIcon color={iconColor("Privacy Policy")} />
    // },
    // {
    //   page: <EmptyPage />,
    //   title: "Terms & Conditions",
    //   icon: <GavelIcon color={iconColor("Terms & Conditions")} />
    // }
  ]

  const handleClick = (settingItem: ISettingItem) => {
    console.log(settingItem);
    setSelectedItem(settingItem)
  }

  useEffect(() => {
    // Check if location.state exists and update_payment is not null
    if (location.state && location.state.update_payment != null) {
      console.log("##L", location.state.update_payment);
      // Check if update_payment is true
      if (location.state.update_payment) {
        setSelectedItem({
          title: 'Payment Methods',
          page: <PaymentMethods />
        });
      }
    }
  }, [location.state]);
  
  
  return (
    <Container fluid className="settings d-flex flex-column page-content-height containerWidth">
      {loading && <Loader />}
      <Row>
        <div>
          <h1 className="page-title">Settings</h1>
        </div>
      </Row>
      <Row className='flex-grow-1 pb-4'>
        <Col md={4} className="d-flex flex-column">
          {settingItems.map((settingItem, i) => (
            <button
              className="item mt-4"
              onClick={() => {
                handleClick(settingItem)
              }}
            >
              <div
                className={`separator ${
                  selectedItem.title === settingItem.title && 'active'
                }`}
              />
              <span className="me-2">{settingItem.icon}</span>
              <div
                className={`title ${
                  selectedItem.title === settingItem.title && 'active'
                }`}
              >
                {settingItem?.title}
              </div>
              {selectedItem.title === settingItem.title && (
                <span className="me-2">{<RightArrowIcon />}</span>
              )}
            </button>
          ))}
          <div className='flex-grow-1'></div>
          {/* <Button className="btn-delete-account" variant='secondary'>Delete Account</Button> */}
        </Col>
        <Col className="mt-4">{selectedItem?.page}</Col>
      </Row>
    </Container>
  )
}

export default PageSettings
