import { useEffect, useState } from 'react'
import Table from '../../../components/table/table'
import { useLazyAllDriversQuery } from '../../../api/network/userApiService'
import {
  DEBOUNCE_TIME,
  userStatus,
  USER_STATUS,
  TRUCK_STATUSES,
  truckStatus,
  TRUCK_STATUS
} from '../../../utils/constants'
import { ReactComponent as VisibilityIcon } from '../../../assets/images/svg/visibility.svg'
import { useNavigate } from 'react-router-dom'
import { ROUTE_NAMES } from '../../../utils/route/routerNames'
import useDebounce from '../../../utils/hooks/useDedounce'

import { FilterValue } from 'react-table'
import Filter from '../../../components/formFilter/formFilter'
//import { Filter_Obj } from '../../../components/formFilter/formFilter/types'
import { getStatusFilterKeys } from '../../../utils/helper'
import { useLazyAllTrucksQuery } from '../../../api/network/truckApiServices'
import { Filter_Obj } from '../../../components/formFilter/types'
import { useLazyAllPaymentHistoryQuery } from '../../../api/network/paymenttApiServices'
import { ITruckTable } from '../../dashboard/truck-list/types'
import { Response } from '../../../api/types/Response'
import { formatAmount } from '../../../utils/NumberUtils'
import { Col } from 'react-bootstrap'
import FormSearch from '../../../components/formSearch/formSearch'


const PaymentList = (props:any) => {
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')

  const [statusFilter, setStatusFilter] = useState<Filter_Obj[]>([])
  const debouncedValue = useDebounce(search, DEBOUNCE_TIME)

  const navigate = useNavigate()
  const [allPaymentsHistoryTrigger, { data, isLoading }] = useLazyAllPaymentHistoryQuery() // Provide an empty object as the argument

  useEffect(() => {
    setPage(1)
    if (statusFilter.length) {
      allPaymentsHistoryTrigger({
        search: debouncedValue,
        page: 1,
        status: getStatusFilterKeys(statusFilter, 'status')
      })
    } else {
      allPaymentsHistoryTrigger({ search: debouncedValue, page: 1 })
    }
  }, [debouncedValue, statusFilter, allPaymentsHistoryTrigger])

  useEffect(() => {
    allPaymentsHistoryTrigger({ search: debouncedValue, page })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, allPaymentsHistoryTrigger])

  const { count, next, previous, results, total_pages } = data || {}

  const onNext = () => {
    setPage(prev => (next ? prev + 1 : prev))
  }
  const onPrev = () => {
    setPage(prev => (previous ? prev - 1 : prev))
  }

  const itemsPerPage = 10

  const columns = [
    {
      Header: 'S No.',
      accessor: (_row: any, i: number) => (page - 1) * itemsPerPage + i + 1
    },
    {
      Header: 'Job ID',
      accessor: 'job'
    },
    {
      Header: 'Transaction ID',
      accessor: 'stripe_payment_intent_id'
    },
    {
      Header: 'Recipient/Sender',
      accessor: 'name'
    },
    {
      Header: 'Payment Status',
      accessor: 'status',
    },
    {
      Header: 'Debit/Credit',
      accessor: 'received',
      Cell: ({ value }: { value: boolean }) => (
        <span className="fc-blue">{value ? 'Credit' : 'Debit'}</span>
      ),
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({ value }: { value: number }) => <span className="fc-blue amount">{formatAmount(value)}</span>
    }
  ]

  return (
    <>
      <Col md={4}>
        <FormSearch search={search} setSearch={setSearch} />
      </Col>
      <Table
        data={results || []}
        columns={columns}
        page={page}
        setPage={setPage}
        onNext={onNext}
        onPrev={onPrev}
        pageCount={Math.ceil((count ?? 0) / itemsPerPage)}
        isLoading={isLoading}
        filter={statusFilter}
        setFilter={setStatusFilter}
        wrapperClassName={`table-jobs-wrap mt-3`}
      />
    </>
  )
}

export default PaymentList
