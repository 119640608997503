import { ACCEPT, REJECT } from './../api/network/ServerUrl'
import { IDNameResponse } from '../api/types/Driver'

export const localStorageKeys = {
  user: 'user',
  accountInfo: 'accountInfo'
}

export const userTypes = {
  ADMIN: '1',
  COMPANY: '2',
  DRIVER: '3'
}

export const companyTypes = {
  BROKER_COMPANY: 1,
  EMPLOYEE_COMPANY: 2,
  WATERFRONT_COMPANY: 3
}

export const userStatus = {
  PENDING_APPROVAL: 1,
  ACTIVE: 2,
  DENIED: 3,
  SUSPENDED: 4
}

export const truckStatus = {
  APPROVED: 1,
  REJECTED: 2,
  PENDING: 3,
  EXPIRED: 4
}

interface USER_STATUS_TYPE {
  [key: number]: string
}

interface TRUCK_STATUS_TYPE {
  [key: number]: string
}

export const USER_STATUS: USER_STATUS_TYPE = {
  1: 'Pending Approval',
  2: 'Active',
  3: 'Denied',
  4: 'Suspended'
}

export const TRUCK_STATUS: TRUCK_STATUS_TYPE = {
  1: 'Approved',
  2: 'Rejected',
  3: 'Pending',
  4: 'Expired'
}

export const DRIVER_DOC_STATUS = {
  NOT_SUBMITTED: 1,
  SUBMITTED: 2,
  REJECTED: 3,
  APPROVED: 4
}

interface JOB_STATUS_TYPE {
  slug: string
  label: string
  status_class: string
}

interface IJOB_STATUSES {
  [key: number]: JOB_STATUS_TYPE
}

interface ITRUCK_STATUSES {
  [key: number]: string
}

interface IPAYMENT_STATUSES {
  [key: number]: string
}

export const JOB_STATUS_NAMES = {
  OPEN: 1,
  ACCEPTED: 2,
  PICKED: 3,
  DOCUMENT_UPLOAD: 4,
  DONE: 5,
  PRICE_BETTING: 6,
  EXPIRED: 7,
  DUMPED: 8,
  DOCUMENT_REJECTED: 9,
  REQUESTED_TRUCK: 10,
  ALLOTED_TRUCK: 11
}

export const JOB_STATUSES: IJOB_STATUSES = {
  1: { slug: 'OPEN', label: 'Posted', status_class: 'orange' },
  2: { slug: 'ACCEPTED', label: 'Accepted', status_class: 'green' },
  3: { slug: 'PICKED', label: 'Picked', status_class: 'green' },
  4: { slug: 'DOCUMENT_UPLOAD', label: 'Submitted', status_class: 'green' },
  5: { slug: 'DONE', label: 'Done', status_class: 'green' },
  6: { slug: 'PRICE_BETTING', label: 'Price Betting', status_class: 'orange' },
  7: { slug: 'EXPIRED', label: 'Expired', status_class: 'red' },
  8: { slug: 'DUMPED', label: 'Dumped', status_class: 'red' },
  9: { slug: 'DOCUMENT_REJECTED', label: 'Rejected', status_class: 'red' },
  10: { slug: 'REQUESTED_TRUCK', label: 'Requested a Truck', status_class: 'orange' },
  11: { slug: 'ALLOTED_TRUCK', label: 'Alloted a Truck', status_class: 'orange' }
}

export const TRUCK_STATUSES: ITRUCK_STATUSES = {
  1: 'Approved',
  2: 'Reject',
  3: 'Pending',
  4: 'Expired'
}

export const JOB_CONFIG = {
  editableForStatuses: [1],
  showTrackingForStatuses: [3, 4, 5, 8, 9, 10]
}

export const DOCUMENT_TYPE: USER_STATUS_TYPE = {
  1: 'Driving License',
  2: 'Twic Card',
  3: 'Social Security Card',
  4: 'Medical Card',
  5: 'Truck Insurance',
  6: 'H6 DMV',
  7: "FMCSA Carrier Authority"
}

export const USER_TYPES: USER_STATUS_TYPE = {
  1: 'Admin',
  2: 'Company',
  3: 'Driver'
}

export const COMPANY_TYPE: USER_STATUS_TYPE = {
  1: 'Broker Company',
  2: 'Employee Company',
  3: 'Waterfront Company'
}
export const companyType: IDNameResponse[] = [
  { name: 'B-Company', id: companyTypes.BROKER_COMPANY },
  { name: 'E-Company', id: companyTypes.EMPLOYEE_COMPANY },
  { name: 'W-Company', id: companyTypes.WATERFRONT_COMPANY }
]
export const GENDER = {
  1: 'Female',
  2: 'Male',
  3: 'Not Say'
}
export const PAGE_SIZE = 10

export const DEBOUNCE_TIME = 500

export const suspensionReasons = [
  "Expired or suspended Driver's License",
  'Expired TWIC Card',
  'Expired Medical Card',
  'Drug Test Needed',
  'Too Many Dumped jobs',
  'Admin Hold',
  'Termination'
]


export const PAYMENT_STATUSES: IPAYMENT_STATUSES = {
  1: 'ON HOLD',
  2: 'Successful',
  3: 'Declined',
}

