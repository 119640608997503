import * as Yup from 'yup'

// const { setLocale } = Yup
// setLocale({
//   mixed: {
//     notType: 'the ${path} is obligatory',
//     required: 'the field ${path} is obligatory',
//     oneOf: 'the field ${path} must have one of the following values: ${values}'
//   }
// })

export const loginFieldsSchema = Yup.object().shape({
  username: Yup.string()
    .trim()
    .email('Please enter valid email address')
    .required('Please enter email address'),

  password: Yup.string()
    .trim()
    .required('Please enter Password')
    .min(8, 'Password is too short - should be 8 chars minimum.')
  // .matches(/[a-z]/, `${i18next.t('smallErrorMessage')}`)
  // .matches(/[A-Z]/, `${i18next.t('capitalErrorMessage')}`)
  // .matches(/[0-9]/, `${i18next.t('numericErrorMessage')}`)
  // .matches(/[!@#$%^&*]/, `${i18next.t('specialCharErrorMessage')}`),
})

export const addDriverSchema = Yup.object().shape({
  name: Yup.string().required('Full Name is required'),
  email: Yup.string()
    .trim()
    .email('Please enter valid email address')
    .required('Email address is required'),
  confirmEmail: Yup.string()
    .trim()
    .required('Please re-enter email')
    .oneOf([Yup.ref('email'), ''], 'Email not match'),

  phone_number: Yup.string().required('Phone no is required'),
  // truck_driver_type: Yup.string().required("Truck Driver Type is required"),
  license_number: Yup.string().required('License number is required'),
  valid_till: Yup.date().required('Valid till date is required'),
  // vehicle_type: Yup.string().required("Vehicle Type is required"),
  // license_plate: Yup.string().required("License Plate is required"),
  street_address: Yup.string().required('Street Address is required'),
  city: Yup.string().required('City is required'),
  zip_code: Yup.string().required('Zip Code is required'),
  state: Yup.string().required('State is required'),
  country: Yup.string().required('Country is required')
})

export const addTruckSchema = Yup.object().shape({
  make_model: Yup.string().required('Modal Name is required'),
  license_plate: Yup.string().required('License Plate is required'),
  truck_number: Yup.string().required('Truck Number is required')
})

export const addCompanySchema = Yup.object().shape({
  // name: Yup.string().required("Full Name is required"),
  email: Yup.string()
    .trim()
    .email('Please enter valid email address')
    .required('Email address is required'),
  confirmEmail: Yup.string()
    .trim()
    .required('Please re-enter email')
    .oneOf([Yup.ref('email'), ''], 'Email not match'),
  phone_number: Yup.string().required('Phone no is required'),
  company_name: Yup.string().required('Company Name is required'),
  contact_person_name: Yup.string().required('Contact Person Name is required'),
  company_type: Yup.string().required('Company type is required')
})

export const feedbackReplySchema = Yup.object().shape({
  text: Yup.string().required('Please type your response ')
})

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email('Please enter valid email address')
    .required('Please enter email address')
})

export const resetPasswordFieldsSchema = Yup.object().shape({
  password: Yup.string()
    .trim()
    .required('Please enter Password')
    .min(8, 'Password is too short - should be 8 chars minimum.'),

  confirmPassword: Yup.string()
    .trim()
    .required('Please re-enter Password')
    .oneOf([Yup.ref('password'), ''], 'Password do not match')
})

export const postJobSchema = Yup.object().shape({
  job_description: Yup.string().required('Job description is required'),
  contact_name: Yup.string().trim().required('Contact name is required'),
  phone_number: Yup.string().required('Phone no is required'),
  // proposed_rate: Yup.string().required("Proposed rate is required"),
  // customer_priority: Yup.string().required("Incentive is required"),
  // total_rate: Yup.string().required("Special Offer is required"),
  load_type: Yup.string().trim().required('Load type is required'),
  size: Yup.string().trim().required('Size is required'),
  weight: Yup.string().trim().required('Weight is required'),
  // job_requirements: Yup.string().required("Job requirement is required"),
  starting_location: Yup.string().required('Starting location is required'),
  ending_location: Yup.string().required('End location is required'),
  scheduled_pick_up_date: Yup.string().required('Pickup date is required'),
  scheduled_pick_up_time: Yup.string().required('Pickup is required'),
  container_number: Yup.string().required('Container number is required'),
  time_limit: Yup.string().required('Time limit is required')
})

export const reAssignJobSchema = Yup.object().shape(
  {
    pickup_address: Yup.string().required('Pick up location is required'),
    company: Yup.string() /*.when('ic_driver', {
    is: '',
    then: (schema) => schema.required('Please select a Company')
  }).required()*/,
    ce_driver: Yup.string(),
    ic_driver: Yup.string() /*.when('company', {
    is: '',
    then: (schema) => schema.required('Please select a WaterFront driver')
  }).required()*/
  } /*, [['company', 'ic_driver']]*/
)

export const changePasswordFieldsSchema = Yup.object().shape({
  old_password: Yup.string().trim().required('Please enter Current Password'),

  new_password: Yup.string()
    .trim()
    .required('Please enter New Password')
    .notOneOf(
      [Yup.ref('old_password'), ''],
      'Password cannot be the same as before'
    )
    .min(8, 'Password is too short - should be 8 chars minimum.'),

  confirm_password: Yup.string()
    .trim()
    .required('Please re-enter Password')
    .oneOf([Yup.ref('new_password'), ''], 'Password do not match')
})

export const paymentFieldsSchema = Yup.object().shape({
  name: Yup.string().trim().required('Please enter your name'),

  account_number: Yup.number()
    .required('Please enter Account Number')
    .positive('Account Number should be positive')
    .integer('Account Number should be integer'),
    // .min(10, 'Account Number is too short - should be 10 chars minimum.')
    // .max(10, 'Account Number is too long - should be 10 chars maximum'),

    routing_number: Yup.string()
    .trim()
    .required('Please Routing Number'),
    bank_name: Yup.string().required('Please enter Bank Name'),
    account_type: Yup.string().required('Please enter Account Type')
})

export const setNewPasswordFieldsSchema = Yup.object().shape({
  new_password: Yup.string()
    .trim()
    .required('Please enter Password')
    .min(8, 'Password is too short - should be 8 chars minimum.'),

  confirm_password: Yup.string()
    .trim()
    .required('Please re-enter Password')
    .oneOf([Yup.ref('new_password'), ''], 'Password do not match')
})
